<section id="blog" class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Productos!</span>
            <h2>Nuestros<b>Productos</b></h2>
            <p>Te ofrecemos una gran variedad de productos, para todas tus ocaciones y festividades</p>
        </div>
        <div class="blog-slides owl-carousel owl-theme">
            <div class="single-blog-post">
                <div class="blog-image">
                    <a routerLink="/blog-details"><img src="assets/img/main-banner3.jpg" alt="image"></a>
                    <div class="post-tag">
                        <a routerLink="#">Lechonas</a>
                    </div>
                </div>
                <div class="blog-post-content">
                    <span class="date">Cojines - Lechonas</span>
                    <h3><a routerLink="/blog-details">La lechona contiene 100% carne de cerdo, arroz y arveja en cantidad mínima; se entrega desgrasada, deshuesada, carnuda</a></h3>
                    <p>Lleva a tu mesa los mejores productos y haz de cada celebración un recuerdo inolvidable en sabor y calidad....</p>
                    <!-- <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-double-right"></i></a> -->
                </div>
            </div>
            <div class="single-blog-post">
                <div class="blog-image">
                    <a routerLink="/blog-details"><img src="assets/img/tamal_about1.png" alt="image"></a>
                    <div class="post-tag">
                        <a routerLink="#">Tamales</a>
                    </div>
                </div>
                <div class="blog-post-content">
                    <span class="date">Tamales</span>
                    <h3><a routerLink="/blog-details">El relleno de este tamal es de pollo, costilla de cerdo, carne de cerdo, huevo cocinado, zanahoria, masa de arroz y de arvejas, todo envuelto con hojas de plátano.</a></h3>
                    <p>Lleva a tu mesa los mejores productos y haz de cada celebración un recuerdo inolvidable en sabor y calidad...</p>                                
                    <!-- <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-double-right"></i></a> -->
                </div>
            </div>
            <div class="single-blog-post">
                <div class="blog-image">
                    <a routerLink="/blog-details"><img src="assets/img/blog3.jpg" alt="image"></a>
                    <div class="post-tag">
                        <a routerLink="#">Perniles</a>
                    </div>
                </div>
                <div class="blog-post-content">
                    <span class="date">Pernil de cerdo</span>
                    <h3><a routerLink="/blog-details">Traemos para ti un delicioso pernil de cerdo, preparado con la mejor dedicación y receta que nos ha categorizado a traves de los años</a></h3>
                    <p>>Lleva a tu mesa los mejores productos y haz de cada celebración un recuerdo inolvidable en sabor y calidad...</p>
                    <!-- <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-double-right"></i></a> -->
                </div>
            </div>
            <div class="single-blog-post">
                <div class="blog-image">
                    <a routerLink="/blog-details"><img src="assets/img/blog4.jpg" alt="image"></a>
                    <div class="post-tag">
                        <a routerLink="#">Empachonas</a>
                    </div>
                </div>
                <div class="blog-post-content">
                    <span class="date">Empachonas</span>
                    <h3><a routerLink="/blog-details">Tenemos las empachonas con auntentica lechona
                        .</a></h3>
                    <p>Lleva a tu mesa los mejores productos y haz de cada celebración un recuerdo inolvidable en sabor y calidad...</p>
                    <!-- <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-double-right"></i></a> -->
                </div>
            </div>
            <div class="single-blog-post">
                <div class="blog-image">
                    <a routerLink="/blog-details"><img src="assets/img/pavos_about1.jpg" alt="image"></a>
                    <div class="post-tag">
                        <a routerLink="#">Pavos</a>
                    </div>
                </div>
                <div class="blog-post-content">
                    <span class="date">Pavos</span>
                    <h3><a routerLink="/blog-details">Pavos rrellenos una receta unica para todas tus festividades</a></h3>
                    <p>Lleva a tu mesa los mejores productos y haz de cada celebración un recuerdo inolvidable en sabor y calidad....</p>
                    <!-- <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-double-right"></i></a> -->
                </div>
            </div>
        </div>
    </div>
</section>