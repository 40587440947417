<footer class="footer-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-7">
                <p>© JOHN ALEXANDER PINILLA CELIS </p>
            </div>
            <div class="col-lg-6 col-md-5">
                <ul>
                    <li><a href="https://www.facebook.com/lechonerialamejordeltolima" target="_blank" title="facebook lechoneria la mejor del tolima"><i class="icofont-facebook"></i></a></li>
                    <li><a href="https://www.instagram.com/lechonerialamejordeltolima/" target="_blank" title="instagram lechoneria la mejor del tolima"><i class="icofont-instagram"></i></a></li>
                    <li><a href="https://wa.me/+573204591442/?text=Hola,%20me%20gustaría%20obtener%20más%20información%20sobre%20los%20productos%20que%20manejan" target="_blank" title="Escribenos al whatsapp"><i class="icofont-whatsapp"></i></a></li>
                </ul>
            </div>
        </div>
    </div>
</footer>

<app-demo-sidebar></app-demo-sidebar>