import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-features',
    templateUrl: './features.component.html',
    styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: 'Todo lo hacemos con amor',
            title: 'LECHONERIA LA MEJOR DEL TOLIMA',
            paragraphText: '!ESTAMOS PARA TODOS TUS EVENTOS¡'
        }
    ]

    singleFeatures: singleFeaturesContent[] = [
        {
            icon: 'icofont-car',
            title: 'Servicio Domicilio',
            paragraphText: 'Te ofrecemos el servicio a domicilio en bogota y sus alrededores'
        },
        {
            icon: 'icofont-pig',
            title: 'Preparacion',
            paragraphText: 'Nuestros productos tiene un sabor unico que nos ha categorizado en los ultimos años.'
        },
        {
            icon: 'icofont-restaurant',
            title: 'Servicio de repartida',
            paragraphText: 'Te ofrecemos un servicio de repartida para cualquier evento que tengas'
        }
    ]

}

class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}

class singleFeaturesContent {
    icon : string;
    title : string;
    paragraphText : string;
}