<section id="contact" class="contact-area ptb-100 bg-image">
    <div class="container">

        <div class="section-title">
            <span>Contactacnos</span>
            <h2>Contactanos</h2>
            <p>Te ofrecemos la mejor calidad con los mejores precios .</p>
        </div>       
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="contact-info">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <ul>
                                <li><i class="icofont-google-map"></i> <span>Punto de Fabrica:</span>Tranversal 13 # 41 - 05 - Soacha (León 13)</li>
                                <li><i class="icofont-google-map"></i> <span>Punto de Venta:</span>Centro Comercial Jardin (Ciudad Verde)</li>
                                <li><i class="icofont-google-map"></i> <span>Punto de Venta:</span>Cl. 42 #8 - 47- Soacha (León 13)</li>
                                <li><i class="icofont-google-map"></i> <span>Punto de Venta:</span>Dg. 37 A # 16-5 -Soacha (Barrio Trebol)</li>
                                <li><i class="icofont-envelope" title="ventasyservicios@lechonerialamejordeltolima.com"></i> <span>Email:</span><a href="mailto:ventasyservicios@lechonerialamejordeltolima.com">ventas y servicios</a></li>
                                <li><i class="icofont-phone"></i> <span>Llamanos:</span>
                                    <a href="tel:+573204591442"> (+57) 320459142&nbsp;</a>
                                    <a href="tel:+573134403317"> (+57) 3134403317&nbsp; </a>
                                    <a href="tel:+573134403317"> (+57) 3134403317 </a></li>

                                <li><a href="https://wa.me/+573204591442/?text=Hola,%20me%20gustaría%20obtener%20más%20información%20sobre%20los%20productos%20que%20manejan" target="_blank"><i class="icofont-whatsapp"></i></a> <span>whatsapp:</span><a href="https://wa.me/+573204591442/?text=Hola,%20me%20gustaría%20obtener%20más%20información%20sobre%20los%20productos%20que%20manejan" target="_blank">Escribenos, Oprime Aquí</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1346.4681395718642!2d-74.19999063689795!3d4.594282352489227!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f9e34185b3933%3A0xb9d9f96674ce5437!2sF%C3%A1brica%20lechoneria%20la%20mejor%20del%20Tolima!5e0!3m2!1ses-419!2sco!4v1733179439156!5m2!1ses-419!2sco"
                        width="ml-auto" height="620" style="border:0;" allowfullscreen="" loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>
                <!-- <div class="contact-form">
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="text" class="form-control" placeholder="Name">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="email" class="form-control" placeholder="Email Address">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <input type="text" class="form-control" placeholder="Subject">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <input type="text" class="form-control" placeholder="Phone Number">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea placeholder="Message" class="form-control" cols="30" rows="5"></textarea>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn btn-primary">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div> -->
            </div>
        </div>
    </div>
</section>