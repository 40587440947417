<div class="home-slides owl-carousel owl-theme">
    <div class="main-banner item-bg2">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content text-center">
                        <span>LA MEJOR LECHONA DEL TOLIMA</span>
                        <h1>LECHONERIA LA MEJOR DEL TOLIMA</h1>
                        <p>Somos una empresa familiar dedicada a la fabricacion de la lechonas, tamales, pavos, perniles con mas de 20 años de experiencia preparando la mejor comida tipica colombiana</p>
                        <div class="btn-box">
                            <a href="#about" class="btn btn-primary">Inicio</a>
                            <!--<span>or</span>
                             <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="icofont-ui-play"></i> Watch Video</a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="main-banner item-bg3">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content text-center">
                        <span>LA MEJOR LECHONA DEL TOLIMA</span>
                        <h1>LECHONERIA LA MEJOR DEL TOLIMA</h1>
                        <p>Somos una empresa familiar dedicada a la fabricacion de la lechonas, tamales, pavos, perniles con mas de 20 años de experiencia preparando la mejor comida tipica colombiana</p>
                        <div class="btn-box">
                            <a href="#about" class="btn btn-primary">Inicio</a>
                            <!--<span>or</span>
                             <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="icofont-ui-play"></i> Watch Video</a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="main-banner item-bg1">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content text-center">
                        <span>LA MEJOR LECHONA DEL TOLIMA</span>
                        <h1>LECHONERIA LA MEJOR DEL TOLIMA</h1>
                        <p>Somos una empresa familiar dedicada a la fabricacion de la lechonas, tamales, pavos, perniles con mas de 20 años de experiencia preparando la mejor comida tipica colombiana</p>
                        <div class="btn-box">
                            <a href="#about" class="btn btn-primary">Inicio</a>
                            <!--<span>or</span>
                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="icofont-ui-play"></i> Watch Video</a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-features></app-features>

<app-about></app-about>

 <!-- <app-cta></app-cta> -->
 

<app-services></app-services>

<!--

<app-who-we-are></app-who-we-are>

<app-skills></app-skills>

<app-portfolio></app-portfolio>

<app-why-we-different></app-why-we-different>

<app-funfacts-two></app-funfacts-two>

<app-team></app-team>

<app-story></app-story>

<app-pricing></app-pricing>

<app-testimonial></app-testimonial>

<app-call-to-action></app-call-to-action>

<app-faq></app-faq>

<app-partner></app-partner>

<app-blog></app-blog>

<app-subscribe></app-subscribe>

<app-contact></app-contact> -->