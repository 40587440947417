<section id="about" class="about-area ptb-100 bg-image">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="row about-image">
                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image">
                            <img src="assets/img/lechoneria_about1.jpg" alt="about">
                        </div>
                    </div>

                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image">
                            <img src="assets/img/pavos_about1.jpg" alt="about">
                        </div>
                    </div>

                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image mt-30">
                            <img src="assets/img/platos_about1.PNG" alt="about">
                        </div>
                    </div>

                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image mt-30">
                            <img src="assets/img/tamal_about1.png" alt="about">
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>Sobre La Lechoneria La Mejor</span>
                    <h2>LECHONERIA LA MEJOR DEL TOLIMA</h2>
                    <p>Con un sabor que nos categoriza, llevamos en la comida la tradiciòn y las construmbres de nuestra region colombia</p>
                    <ul>
                        <li>Estamos para todos tus Eventos</li>
                        <li>Servicio a domicilio</li>
                        <li>Servicio de repartida </li>
                    </ul>
                    <p>Cada producto que hacemos para ti, se convierte en algo magico, donde tiene los mejores ingredientes, el mejor sazòn pero los mas importante cada comida esta echa con amor.</p>
                </div>
            </div>
        </div>
    </div>
</section>