<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container">
        <a class="navbar-brand" routerLink="/">
            <img src="assets/img/LogoLechoneria.png" alt="logo">
            <img src="assets/img/LogoMini.png" alt="logo">
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><span class="nav-link" (click)="onClick('home')">Inicio</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('about')">Sobre Nosotros</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('services')">Servicio</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('blog')">Productos</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('contact')">Contactanos</span></li><!--
                <li class="nav-item"><span class="nav-link" (click)="onClick('pricing')">Pricing</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('testimonial')">Testimonial</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('blog')">Blog</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('contact')">Contact</span></li> -->
            </ul>
        </div>
    </div>
</nav>